import { useLocation } from "react-router-dom";
import { format, parse } from "date-fns";
import {
    Article,
    // Article,
    ArticleForm,
    ArticleStatus,
//    ArticleStatusName,
} from "../gateways/cms/article";
import { useCreateArticleMutation } from "../gateways/cms/articleApi";
import { useGetDefaultUrlQuery } from "../gateways/cms/schemaApi";
import { Dialog, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { FormEventHandler, useState } from "react";
import { confirmWithStyle } from "./MyConfirm";

import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
// import Grid from "@mui/material/Grid";
import { SelectDate } from "./SelectDate";
import { SelectDay } from "./SelectDay";
import iconRadioOff from "../assets/icons/icon_radiobutton_off.svg";
import iconRadioOn from "../assets/icons/icon_radiobutton_on.svg";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";
import iconCalendar from "../assets/icons/icon_calendar.svg";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

// style
const DialogOuter = styled(Dialog)`
    .MuiDialog-paper {
        width: 800px;
        max-width: 100%;
        border-radius: 16px;

        header {
            text-align: center;
            padding: 40px 32px 0;

            h1 {
                font-size: 24px;
                font-weight: normal;
                margin: 0;
                line-height: 1;
            }
            .message {
                margin: 40px 0 0;
            }
        }
        main {
            flex: 1;
            overflow: auto;
            margin: 40px 0;
            padding: 0 64px;

            & > section {
                display: flex;

                h2 {
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    height: 32px;
                    width: 20%;
                    margin-top: calc(1em + 8px);
                    margin-right: 40px;
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: normal;
                }
                h2 + * {
                    flex: 1;
                }

                b {
                    font-weight: normal;
                }

                input[type="text"] {
                    height: 32px;
                    border: 1px solid #daddde;
                    border-radius: 2px;
                    padding: 4px;
                }
            }
            & > section + section {
                margin-top: 40px;
            }

            div.text {
                display: flex;
                flex-direction: column;
            }
            .schedule {
                & > label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    min-height: 24px;
                }
            }
        }
        footer {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 64px;
            padding: 0 32px;
            background-color: #f4f4f4;

            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #c9cbd3;
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }

        .copysource {
            h2 + div {
                display: flex;

                .id {
                    width: 6em;
                    margin-right: 24px;
                }
                .id + label {
                    flex: 1;
                }
            }
        }
        .newitem {
            ul {
                li + li {
                    margin-top: 24px;
                }
            }
        }
    }

    label {
        /* display: block; */
        margin-bottom: 8px;
        line-height: 1;
    }

    &.duplicate_from_article {
        .message,
        section.newitem,
        footer {
            .schedule {
                display: none;
            }
        }
    }
    &.duplicate_from_schedule {
        .message,
        section.newitem,
        footer {
            .article {
                display: none;
            }
        }
    }
`;
const InputWeekly = styled(Stack)`
    margin-bottom: 16px;

    label {
        &:nth-of-type(1) {
            color: #f00;
        }
        &:nth-of-type(7) {
            color: #00f;
        }

        display: flex;
        align-items: center;

        input[type="checkbox"] {
            width: 0;
            height: 0;
            margin: 0 calc(24px + 4px) 0 0;

            &::before {
                content: "";
                display: block;
                width: 24px;
                height: 24px;

                background-color: transparent;
                background-attachment: scroll;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url(${iconCheckboxOff});

                transform: translate(0, -50%);
            }
            &:checked::before {
                background-image: url(${iconCheckboxOn});
            }
        }
    }
`;
const InputCalender = styled("section")`
    .selectCalender {
        display: flex;
        flex-wrap: wrap;
        width: calc((2.5em * 7) + 2px);
        margin-bottom: 16px;
        border: 1px solid #ccc;
        border-width: 2px 0 0 2px;

        label {
            width: 2.5em;
            height: 2.5em;
            margin: 0;
            position: relative;

            input {
                position: absolute;
                z-index: 0;
                opacity: 0;

                &:checked + b {
                    background-color: #3a3e5d;
                    color: #fff;
                }
            }
            b {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #ccc;
                border-width: 0 2px 2px 0;
                width: 2.5em;
                height: 2.5em;
                font-weight: normal;
            }
        }

        & + div {
            width: calc((2.5em * 7) + 2px);
            justify-content: space-around;
        }
    }
`;
const LabelRadio = styled("label")`
    display: flex;
    align-items: center;
    position: relative;

    & > div {
        align-items: center;
    }

    input[type="radio"] {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        transform: translate(-50%, -50%);

        & + span {
            display: flex;
            align-items: center;
            margin-left: 0 !important;

            &::before {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 0.5em;
                pointer-events: none;
                background: transparent url(${iconRadioOff}) scroll no-repeat center center;
            }
        }
        &:checked + span::before {
            background-image: url(${iconRadioOn});
        }

        & ~ b {
            margin-left: 0.65em;
            font-weight: normal;
        }
    }
`;
const InputDate = styled("input")`
    padding: 4px;

    &::-webkit-calendar-picker-indicator {
        background: transparent url(${iconCalendar}) center center no-repeat scroll;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            background-color: #00000018;
        }
    }
`;
const InputTime = styled("input")`
    padding: 4px;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        background-image: none;
    }
`;

interface Props {
    article: Article;
    type: string;
    deliveryGroupId: number;
}

export const ArticleDuplicateModal = ({ article }: Props) => {
    const [articleCreate] = useCreateArticleMutation();
    // TODO: ここでnavigate使うの良くないけど変更しすぎると大変だからとりあえずこれで
    const navigate = useNavigate();

    const [title, setTitle] = useState(article.title + "のコピー");
    // ArticleForm.tsx から移植
    const [articleType] = useState(String(article?.schemaGroupId || ""));
    const [originalArticleId] = useState(article.id || 0);
    const [periodDayStartDate, setPeriodDayStartDate] = useState(date(article.periodDayStart));
    const [periodDayStartTime, setPeriodDayStartTime] = useState(time(article.periodDayStart));
    const [periodDayEndDate, setPeriodDayEndDate] = useState(date(article.periodDayEnd));
    const [periodDayEndTime, setPeriodDayEndTime] = useState(time(article.periodDayEnd));
    const [periodDayNoLimit, setPeriodDayNoLimit] = useState(!article.periodDayStart && !article.periodDayEnd);
    const [periodWeek, setPeriodWeek] = useState(article.periodWeek || [1, 1, 1, 1, 1, 1, 1]);
    const [periodDay, setPeriodDay] = useState(article.periodDay || [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]);
    const [periodTimeStart, setPeriodTimeStart] = useState(article?.periodTimeStart || "00:00");
    const [periodTimeEnd, setPeriodTimeEnd] = useState(article?.periodTimeEnd || "23:59");

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const duplicatetype = "duplicate_from_" + queryParams.get("type");
    const deliveryId = Number(queryParams.get("deliveryGroupId"));

    // // TODO: 全ての項目を持たせる
    const articleForm: ArticleForm = {
        ...article,
        status: ArticleStatus.DRAFT,
        title,
        articleType,
        periodDayStart: periodDayString(periodDayStartDate, periodDayStartTime, periodDayNoLimit),
        periodDayEnd: periodDayString(periodDayEndDate, periodDayEndTime, periodDayNoLimit),
        periodWeek,
        periodDay,
        periodTimeStart,
        periodTimeEnd,
        originalArticleId: originalArticleId,
    };

    // コピーのみ と コピーを新規掲載 の際に渡す値が同じかわからなかったので別に用意
    const saveAs = async (status: ArticleStatus) => {
        // TODO: ちゃんとしたバリデーション

        const articleFormSave: ArticleForm = {
            ...article,
            status,
            title,
            articleType,
            periodDayStart: periodDayString(periodDayStartDate, periodDayStartTime, periodDayNoLimit),
            periodDayEnd: periodDayString(periodDayEndDate, periodDayEndTime, periodDayNoLimit),
            periodWeek,
            periodDay,
            periodTimeStart,
            periodTimeEnd,
            deliveryGroupId: deliveryId,
            originalArticleId: originalArticleId,
        };

        // articleCreate(articleFormSave).then(() => navigate("/articles?status=50"));
        await articleCreate(articleFormSave);
        navigate(`/delivery-groups/${deliveryId}`);
    };

    const handleDismiss: FormEventHandler = (e) => {
        e.preventDefault();
        navigate(-1);
    };

    const GetDefaultUrl = useGetDefaultUrlQuery();
    const handleDuplicate = async () => {
        if((article.disk?.free || article.disk?.free === 0) && article.fileSize && article.disk.free < article.fileSize){
            alert("ディスク使用量がディスク容量上限を超えています");
        }
        else{
            const isConfirmed = await confirmWithStyle({
                title: "下書きに保存",
                body: "コンテンツをコピーして下書きに保存します。\nよろしいですか？",
                okButtonText: "下書きに保存",
            });
            if (isConfirmed) {
                await articleCreate(articleForm);
                let navi = GetDefaultUrl.currentData || "/articles?status=50";
                navigate(String(navi));
            }
        }
        
    };

    // const handleDraft: FormEventHandler = (e) => {
    //     e.preventDefault();
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!confirm("選択したコンテンツを下書きに移動します。よろしいですか？")) return;
    //     saveAs(ArticleStatus.DRAFT);
    // };
    const handleDraft = async () => {
        if((article.disk?.free || article.disk?.free === 0) && article.fileSize && article.disk.free < article.fileSize){
            alert("ディスク使用量がディスク容量上限を超えています");
        }
        else{
            const isConfirmed = await confirmWithStyle({
                title: "下書きに保存",
                body: "コンテンツをコピーして下書きに保存します。\nよろしいですか？",
                okButtonText: "下書きに保存",
            });
            if (isConfirmed) {
                saveAs(ArticleStatus.DRAFT);
            }
        }
    };

    // const handlePublish: FormEventHandler = (e) => {
    //     e.preventDefault();
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!confirm("コンテンツをそのまま掲載します。よろしいですか？")) return;
    //     saveAs(ArticleStatus.PUBLISHED);
    // };
    const handlePublish = async () => {
        if((article.disk?.free || article.disk?.free === 0) && article.fileSize && article.disk.free < article.fileSize){
            alert("ディスク使用量がディスク容量上限を超えています");
        }
        else{
            const isConfirmed = await confirmWithStyle({
                title: "掲載",
                body: "コンテンツをコピーして掲載します。\nよろしいですか？",
                okButtonText: "掲載",
            });
            if (isConfirmed) {
                saveAs(ArticleStatus.PUBLISHED);
            }
        }
    };

    // const handlePublishAndNotify: FormEventHandler = (e) => {
    //     e.preventDefault();
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!confirm("コンテンツをテナント管理者と一般ユーザー全員に通知して掲載します。よろしいですか？")) return;
    //     saveAs(ArticleStatus.PUBLISHED);
    //     // TODO: 通知
    // };
    /** 通知して掲載が非表示になったためコメントアウト
    const handlePublishAndNotify = async () => {
        const isConfirmed = await confirmWithStyle({
            title: "通知して掲載",
            body: "コンテンツをテナント管理者と一般ユーザー全員に通知して掲載します。よろしいですか？",
            okButtonText: "掲載",
        });
        if (isConfirmed) {
            saveAs(ArticleStatus.PUBLISHED);
            // TODO: 通知
        }
    };
    コメントアウトここまで  **/

    // 全部表示版を作成
    // 表示時の引数で縮小版を表示
    // TODO: モーダル( Dialog )を開くと、表示に影響が出る原因を調査

    return (
        <DialogOuter aria-labelledby="label" open onClose={handleDismiss} className={`${duplicatetype}`}>
            <header>
                <h1>コンテンツをコピーします。よろしいですか？</h1>
                <p className="message">
                    <span className="article">※コピーされたコンテンツは下書きに保存されます。</span>
                    <span className="schedule">コンテンツタイプ、配信先、請求情報は詳細画面より変更してください。</span>
                </p>
            </header>

            <main className={`${duplicatetype}`}>
                <section className="copysource">
                    <h2>元コンテンツ</h2>
                    <div>
                        <div className="text id">
                            <label>ID</label>
                            <input type="text" value={article.id} id="sourceId" disabled />
                        </div>
                        <div className="text title">
                            <label>タイトル</label>
                            <input type="text" value={article.title} id="sourceTitle" disabled />
                        </div>
                    </div>
                </section>

                <section className="newitem">
                    <h2>新規コンテンツ</h2>
                    <div>
                        <form>
                            <ul>
                                <li>
                                    <div className="text">
                                        <label>タイトル</label>
                                        <input
                                            type="text"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            id="title"
                                        />
                                    </div>
                                </li>
                                <li className="schedule">
                                    <label htmlFor="schedule">掲載期間</label>
                                    <LabelRadio>
                                        <Stack spacing={1} direction="row">
                                            <input
                                                type="radio"
                                                checked={!periodDayNoLimit}
                                                onChange={(e) => setPeriodDayNoLimit(!e.target.checked)}
                                            />
                                            <span></span>

                                            <InputDate
                                                type="date"
                                                value={periodDayStartDate}
                                                onChange={(e) => setPeriodDayStartDate(e.target.value)}
                                            />
                                            <InputTime
                                                type="time"
                                                value={periodDayStartTime}
                                                onChange={(e) => setPeriodDayStartTime(e.target.value)}
                                            />
                                            <span>〜</span>
                                            <InputDate
                                                type="date"
                                                value={periodDayEndDate}
                                                onChange={(e) => setPeriodDayEndDate(e.target.value)}
                                            />
                                            <InputTime
                                                type="time"
                                                value={periodDayEndTime}
                                                onChange={(e) => setPeriodDayEndTime(e.target.value)}
                                            />
                                        </Stack>
                                    </LabelRadio>
                                    <LabelRadio>
                                        <input
                                            type="radio"
                                            checked={periodDayNoLimit}
                                            onChange={(e) => setPeriodDayNoLimit(e.target.checked)}
                                        />
                                        <span></span>
                                        <b>無期限で掲載する</b>
                                    </LabelRadio>
                                </li>
                                <li className="schedule">
                                    <label>繰返し（曜日指定）</label>
                                    <InputWeekly spacing={1} direction="row">
                                        <SelectDay value={periodWeek} onChange={setPeriodWeek} />
                                    </InputWeekly>

                                    <label>繰返し（日付指定）</label>
                                    <InputCalender>
                                        <SelectDate value={periodDay} onChange={setPeriodDay} />
                                    </InputCalender>
                                </li>
                                <li className="schedule">
                                    <label htmlFor="periodTimeStart">掲載時間</label>
                                    <label>
                                        <Stack spacing={1} direction="row">
                                            <InputTime
                                                type="time"
                                                id="periodTimeStart"
                                                value={periodTimeStart}
                                                onChange={(e) => setPeriodTimeStart(e.target.value)}
                                            />
                                            <span>〜</span>
                                            <InputTime
                                                type="time"
                                                id="periodTimeEnd"
                                                value={periodTimeEnd}
                                                onChange={(e) => setPeriodTimeEnd(e.target.value)}
                                            />
                                        </Stack>
                                    </label>
                                </li>
                            </ul>
                        </form>
                    </div>
                </section>
            </main>

            <footer>
                <div className="article">
                    <Button variant="outlined" onClick={handleDismiss}>
                        キャンセル
                    </Button>
                    <Button variant="contained" disableElevation disabled={!title} onClick={handleDuplicate}>
                        OK
                    </Button>
                </div>
                <div className="schedule">
                    <Button variant="outlined" onClick={handleDismiss}>
                        キャンセル
                    </Button>
                    <Button variant="outlined" onClick={handleDraft}>
                        下書き保存
                    </Button>
                    <Button variant="contained" disableElevation onClick={handlePublish}>
                        掲載
                    </Button>
                    {/* 通知して掲載が非表示になったためコメントアウト
                    <Button variant="contained" disableElevation onClick={handlePublishAndNotify}>
                        通知して掲載
                    </Button>
                    コメントアウトここまで */}
                </div>
            </footer>
        </DialogOuter>
    );
};

export function date(dateTime?: string): string {
    if (!dateTime) {
        return "";
    }
    return format(parse(dateTime, "yyyy-MM-dd HH:mm:ss", new Date()), "yyyy-MM-dd");
}

export function time(dateTime?: string): string {
    if (!dateTime) {
        return "";
    }
    return format(parse(dateTime, "yyyy-MM-dd HH:mm:ss", new Date()), "HH:mm:ss");
}

export function periodDayString(date?: string, time?: string, noLimit?: boolean): string | null | undefined {
    if (noLimit) {
        return null;
    }
    if (!date || !time) {
        return undefined;
    }
    if(time.length < 6){
        time += ":00";
    }
    return date + " " + time;
}
