import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { Id } from "./id";
import { Page } from "./page";
import { Article, ArticleForm, ArticleQuery, ArticleUpdateStatusForm, 
         DeliveryOrganizationForm, DeliveryOrganizationQuery } from "./article";
import { User, UserForm, UserQuery } from "./user";
import { ScheduleQuery, ScheduleForm } from "./schedule";

export const articleApi = createApi({
    reducerPath: "articleApi",
    baseQuery,
    tagTypes: ['Article', 'User'],
    endpoints: (builder) => ({
        getArticles: builder.query<Page<Article>, ArticleQuery>({
            query: (params) => ({
                url: "articles/manage/list",
                params,
            }),
            providesTags: ['Article','User'],
        }),
        getArticle: builder.query<Article, Id>({
            query: (id) => `articles/manage/${id}`,
            providesTags: ['Article'],
        }),
        getDeliveryOrganizations: builder.query<DeliveryOrganizationForm[], DeliveryOrganizationQuery>({
            query: (params) => ({
                url: `deliverygroups/organizationlist`,
                params,
            }),
            providesTags: ['Article'],
        }),
        getDailySchedule: builder.query<Article[], ScheduleQuery>({
            query: (params) => ({
                url: `schedule/daily`,
                params,
            }),
            providesTags: ['Article'],
        }),
        getMonthlySchedule: builder.query<Article[], ScheduleQuery>({
            query: (params) => ({
                url: `schedule/monthly`,
                params,
            }),
            providesTags: ['Article'],
        }),
        getUsers: builder.query<Page<User>, UserQuery | void>({
            query: (params) => {
                return { url: "users/list", params: params || undefined };
            },
            providesTags: ['Article','User'],
        }),
        getUser: builder.query<User, Id>({
            query: (id) => ({ url: `users/${id}` }),
            providesTags: ['Article','User'],
        }),
        getMyUser: builder.query<User, void>({
            query: () => ({ url: "users/me" }),
            providesTags: ['Article','User'],
        }),
        createArticle: builder.mutation<void, ArticleForm>({
            query: (body) => {
                return {
                    url: `articles/manage`,
                    method: "post",
                    body,
                };
            },
            invalidatesTags: ['Article'],
        }),
        updateArticle: builder.mutation<void, ArticleForm>({
            query: ({ id, ...body }) => ({
                url: `articles/manage/${id}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Article'],
        }),
        bulkDeleteArticles: builder.mutation<void, number[]>({
            query: (articleIds) => ({
                url: `articles/manage`,
                method: "delete",
                body: {
                    articleIds,
                },
            }),
            invalidatesTags: ['Article'],
        }),
        bulkUpdateArticleStatus: builder.mutation<void, ArticleUpdateStatusForm>({
            query: (body) => ({
                url: "articles/manage/status",
                method: "post",
                body,
            }),
            invalidatesTags: ['Article'],
        }),
        updateDailySchedule: builder.mutation<void, ScheduleForm>({
            query: ({ deliveryGroupId, body }) => ({
                url: `schedule/daily/${deliveryGroupId}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Article'],
        }),
        updateMonthlySchedule: builder.mutation<void, ScheduleForm>({
            query: ({ deliveryGroupId, body }) => ({
                url: `schedule/monthly/${deliveryGroupId}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Article'],
        }),
        updateUser: builder.mutation<void, UserForm>({
                    query: ({ id, ...body }) => ({
                        url: `users/edit/${id}`,
                        method: "post",
                        body,
                    }),
            invalidatesTags: ['Article','User'],
        }),
        deleteUser: builder.mutation<void, Id>({
            query: (id) => ({ url: `users/${id}`, method: "delete" }),
            invalidatesTags: ['User'],
        }),
        enableUser: builder.mutation<void, Id>({
            query: (id) => ({ url: `users/enable/${id}`, method: "post" }),
            invalidatesTags: ['User'],
        }),
        disableUser: builder.mutation<void, Id>({
            query: (id) => ({ url: `users/disable/${id}`, method: "post" }),
            invalidatesTags: ['User'],
        }),
    }),
});

export const {
    useGetArticlesQuery,
    useGetArticleQuery,
    useGetDeliveryOrganizationsQuery,
    useGetDailyScheduleQuery,
    useGetMonthlyScheduleQuery,
    useGetUsersQuery,
    useGetUserQuery,
    useGetMyUserQuery,
    useCreateArticleMutation,
    useUpdateArticleMutation,
    useBulkDeleteArticlesMutation,
    useBulkUpdateArticleStatusMutation,
    useUpdateDailyScheduleMutation,
    useUpdateMonthlyScheduleMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useEnableUserMutation,
    useDisableUserMutation,
} = articleApi;
