import { useSelector } from "react-redux";
import { useGetMyUserQuery } from "../../gateways/cms/articleApi";
import { RootState } from "../../store";

export function useAuth() {
    const isLoggedIn = useSelector<RootState>((state) => !!state.auth.token);
    const user = useGetMyUserQuery().data;

    return {
        user,
        isLoggedIn,
    };
}
