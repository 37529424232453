import { useState, useLayoutEffect } from "react";
import axios from 'axios';
import Config from "../../Config";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { NavLink } from "react-router-dom";
import { List } from "@mui/material";
import { useGetMyUserQuery } from "../../gateways/cms/userApi";
import { useGetTaskCountQuery } from "../../gateways/cms/taskApi";
import { useGetDefaultUrlQuery } from "../../gateways/cms/schemaApi";

import Color from "../../styles/colors.js";
import VarConst from "../../styles/variables.js";

const drawerWidth: number = 240;
const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(
    ({ theme, open }) => ({
        "& .MuiDrawer-paper": {
            position: "relative",
            whiteSpace: "nowrap",
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            ...(!open && {
                overflowX: "hidden",
                transition: theme.transitions.create("width", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up("sm")]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
    `

.MuiDrawer-paper {
    // background-color: var.$RGB-primary;
    background-color: ${Color.primary};
    width: 260px;

    .MuiList-root{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        // padding-top: $header-H;
        padding-top: ${VarConst.headerH};

        .MuiBox-root {
            width: 100%;
            padding-top: 40px;


            &:nth-of-type(3) {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: 40px;
            }

            &:nth-of-type(1) {
                a:nth-of-type(1) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_monitor.svg`)} ); }     // @include icon.Svg-monitor(#fff);
                a:nth-of-type(2) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_task.svg`)} ); }     // @include icon.Svg-task(#fff);
                a:nth-of-type(3) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_movie.svg`)} ); }     // @include icon.Svg-movie(#fff);
                a:nth-of-type(4) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_list.svg`)} ); }     // @include icon.Svg-list(#fff);
                a:nth-of-type(5) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_calendar.svg`)} ); }     // @include icon.Svg-calendar(#fff);
            }
            &:nth-of-type(2) {
                a:nth-of-type(1) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_setting.svg`)} ); }     // @include icon.Svg-setting(#fff);
                a:nth-of-type(2) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_shop.svg`)} ); }     // @include icon.Svg-shop(#fff);
                a:nth-of-type(3) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_member.svg`)} ); }     // @include icon.Svg-member(#fff);
                a:nth-of-type(4) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_paper_DL.svg`)} ); }     // @include icon.Svg-paper_DL(#fff);
            }
            &:nth-of-type(3) {
                a:nth-of-type(1) .MuiListItemIcon-root { background-image: url( ${require(`../../assets/globalmenu/icon_maintenance.svg`)} ); }     // @include icon.Svg-maintenance(#fff);
            }
        }
    }

    a {
        color: #fff;
        padding: 16px 16px 16px 24px;
        font-size: 14px;

        &.active,
        &:hover {
            // background-color: var.$RGB-nav_hover;
            background-color: ${Color.navHover};
        }

        // 元々のアイコン
        .MuiListItemIcon-root {
            min-width: 24px;
            margin-right: 8px;
        }
        svg {
            color: transparent;
        }
    }
}

`
);

export interface DrawerMenuProps {
    isDrawerOpen: boolean;
    toggleDrawer: () => void;
}

export default function DrawerMenu({ isDrawerOpen, toggleDrawer }: DrawerMenuProps) {
    const GetTaskCount = useGetTaskCountQuery();
    const GetDefaultUrl = useGetDefaultUrlQuery();
    const [defaultUrl, setDefaultUrl] = useState(GetDefaultUrl.currentData || "/articles?status=50");
    const [taskCount, setTaskCount] = useState(String(GetTaskCount.data || "0"));
    const token = useSelector((state: RootState) => state.auth.token);
    //console.log(GetDefaultUrl.data);
    //console.log(defaultUrl);
    useLayoutEffect(() => {
        if (typeof GetTaskCount.currentData === "string") {
            setTaskCount(GetTaskCount.currentData);
        }
    },[GetTaskCount]);

    useLayoutEffect(() => {
        if (typeof GetDefaultUrl.currentData === "string") {
            setDefaultUrl(GetDefaultUrl.currentData);
        }
    },[GetDefaultUrl]);

    // ユーザー情報取得
    const user = useGetMyUserQuery();
    if (!user.data) {
        return <div>loading</div>;
    };

    /*** 未対応タスク数を取得する関数の定義 ***/
    // ヘッダー
    const instance = axios.create({
        baseURL: Config.baseEndpoint,
        headers: {
            Accept: "application/json, application/octet-stream",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    // 未対応タスク数取得
    const getTaskCount = async() => {
        // ここでAPIを叩いてデータを取得
        const response = await instance.get('/task_count');
        if (typeof response.data === "string"){
            setTaskCount(response.data);
        }else{
            console.log('タスク数の型がunkown:', response.data);
        }
    }
    // 1分ごとに未対応タスク数取得関数を実行
    setInterval(getTaskCount, 60 * 60 * 1000);

    // 表示するメニュー（権限）のリスト
    // const menu = user.data.authority;
    return (
        <Drawer
            className="globalmenu"
            // sx={{
            //   width: drawerWidth,
            //   flexShrink: 0,
            //   '& .MuiDrawer-paper': {
            //     width: drawerWidth,
            //     boxSizing: 'border-box',
            //   },
            // }}
            variant="permanent"
            anchor="left"
            // variant="permanent"
            // open={isDrawerOpen}
        >
            {/*      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
*/}

            <List className="globalmenu-main">
                {/* エラーになるので一旦コメントアウト
        {
          ///// "端末のステータス監視" /////
          userInfo.menu.indexOf('TerminalStatusList') !== -1 && (
            <ListItem component={Link} to="/">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="端末ステータス監視" />
            </ListItem>
          )
        }
        {
          ///// "購買者ログ" /////
          userInfo.menu.indexOf('CustomerLog') !== -1 && (
            <ListItem component={Link} to="/logs/seventeen/customer">
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="購買者ログ" />
            </ListItem>
          )
        }
        */}
                <Box>
                    <ListItem component={NavLink} to="/logs/terminal-status">
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="端末状態" />
                    </ListItem>

                    <ListItem component={NavLink} to="/tasks">
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="タスク管理" />
                        {(taskCount && taskCount > "0") && (
                            <Chip
                                label={taskCount}
                                color="error"
                                size="small"
                            />
                        )}
                    </ListItem>

                    <ListItem component={NavLink} to={String(defaultUrl)}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="コンテンツ管理" />
                    </ListItem>

                    <ListItem component={NavLink} to="/delivery-groups">
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="掲載順管理" />
                    </ListItem>

                    {!user.data?.checkAgent ? (
                        <ListItem component={NavLink} to="/delivery-rules">
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="稼働スケジュール管理" />
                        </ListItem>
                    ):(<ListItem></ListItem>)}
                </Box>

                {/* Menu Grounp 2*/}
                {user.data?.checkAdmin ? (
                    <Box>
                        <ListItem component={NavLink} to="/schema-definitions">
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="コンテンツスキーマ管理" />
                        </ListItem>

                        <ListItem component={NavLink} to="/organizations">
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="テナント管理" />
                        </ListItem>

                        <ListItem component={NavLink} to="/users">
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="ユーザー管理" />
                        </ListItem>

                        <ListItem component={NavLink} to="/logs/billing-data">
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="請求データダウンロード" />
                        </ListItem>
                    </Box>
                ):(<Box></Box>)}

                {/* Menu Grounp Bottom */}
                <Box>
                    <ListItem component={NavLink} to="/">
                        <ListItemIcon>
                            {" "}
                            <DashboardIcon />{" "}
                        </ListItemIcon>
                        <ListItemText primary="設定" />
                    </ListItem>
                </Box>
            </List>
        </Drawer>
    );
}
