import { useNavigate, useParams } from "react-router-dom";
import { UserForm } from "../../components/UserForm";
import {
    useDeleteUserMutation,
    //useGetUserQuery,
    useResetPasswordMutation,
} from "../../gateways/cms/userApi";
import { useGetUserQuery, useUpdateUserMutation } from "../../gateways/cms/articleApi";
import MainLayout from "../../layouts/MainLayout";

import { styled } from "@mui/system";
import ButtonMUI from "@mui/material/Button";


// style
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;
const Button = styled(ButtonMUI)`
    &,
    &.MuiButton-outlined {
        min-width: 116px;
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #C9CBD3;
    }
    &.destructive {
        color: #FD6F6C;
    }
`;

export function UserEditPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const user = useGetUserQuery(id!);
    const [userUpdate] = useUpdateUserMutation();
    const [userDelete] = useDeleteUserMutation();
    const [userResetPassword] = useResetPasswordMutation();

    const deleteUser = () => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm("ユーザーを削除します。よろしいですか？")) return;
        userDelete(id!);
        navigate(-1);
    };

    const resetPassword = () => {
        if (!user.data) throw new Error("user is not loaded");
        userResetPassword({ email: user.data.email });
    };
    
    return (
        <MainLayout title="ユーザー管理（編集）">
            <PageTitle>
                <h1>ユーザー管理（編集）</h1>
            </PageTitle>


            { user.data && <UserForm
                user={user.data}
                onSubmit={userUpdate}
                onDismiss={() => navigate(-1)} 
                passResetButton={<Button type="button" variant="outlined" onClick={resetPassword}>パスワードをリセット</Button>}
                deleteButton={<Button variant="outlined" className="destructive" onClick={deleteUser}>アカウント削除</Button>}
            />}
        </MainLayout>
    );
}
